import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Input from '@mui/material/Input';
import { Typography, Container, TableContainer, Table, TableBody, TableRow, TableCell, Paper, TextField, MenuItem, Divider, TableHead } from '@mui/material';

const RenderData = ({ title, data }) => (
    <>
        <Typography variant="h5" gutterBottom>{title}</Typography>
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {Object.entries(data).map(([key, value]) => (
                        <TableRow key={key}>
                            <TableCell>{key}</TableCell>
                            <TableCell>
                                {typeof value === 'object' ? (
                                    <Table>
                                        <TableBody>
                                            {Object.entries(value).map(([subKey, subValue]) => (
                                                <TableRow key={subKey}>
                                                    <TableCell>{subKey}</TableCell>
                                                    <TableCell>{subValue}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                ) : value}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
);

const FileUpload = () => {
    const [file, setFile] = useState(null);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const [dropdown1Value, setDropdown1Value] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://app.rmhospitality.co/api/items');
                const result = response.data;
                setData(result);
                console.log('result', result);
            } catch (error) {
                setError(error.message || 'An error occurred');
            }
        };

        fetchData();
    }, []);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        try {
            setLoading(true); // Set loading state to true when upload starts
            const apiUrl = `https://app.rmhospitality.co/api/read/?col1_name=${dropdown1Value}&col2_name=${dropdown1Value}`;
            const formData = new FormData();
            formData.append('file_uploaded', file);

            const response = await fetch(apiUrl, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setResult(data);
                console.log('Response data:', data);
                console.log('Server message:', data.message);
                console.log('File uploaded successfully');
            } else {
                console.error('Error uploading file');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false); // Set loading state to false when upload is complete (whether successful or not)
        }
    };

    const handleDropdown1Change = (event) => {
        setDropdown1Value(event.target.value);
    };

    return (
        <>
            <Container className="hero-section">
                <div className="div">
                    <h1>RMH Proposal Tool</h1>
                    <Input style={{ margin: '10px' }} className='' type='file' onChange={handleFileChange} placeholder="Enter your text" />
                    <Button style={{ margin: '10px' }} onClick={handleUpload} size="small" component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                        Upload file
                    </Button>

                    <div className='flex'>
                        <TextField
                            size="small"
                            select
                            label="Select Column"
                            value={dropdown1Value}
                            onChange={handleDropdown1Change}
                            style={{ margin: '10px', width: '200px' }}
                        >
                            {[
                                { value: "column_1", label: "Column 1" },
                                { value: "column_2", label: "Column 2" },
                                { value: "column_3", label: "Column 3" },
                                { value: "column_4", label: "Column 4" },
                                { value: "column_5", label: "Column 5" },
                                { value: "column_6", label: "Column 6" },
                                { value: "column_7", label: "Column 7" },
                                { value: "column_8", label: "Column 8" },
                                { value: "column_9", label: "Column 9" },
                                { value: "column_10", label: "Column 10" },
                                { value: "column_11", label: "Column 11" },
                                { value: "column_12", label: "Column 12" },
                                { value: "column_13", label: "Column 13" },
                                { value: "column_14", label: "Column 14" },
                                { value: "column_15", label: "Column 15" },
                                { value: "column_16", label: "Column 16" },
                                { value: "column_17", label: "Column 17" },
                                { value: "column_18", label: "Column 18" }
                            ].map((column) => (
                                <MenuItem key={column.value} value={column.value}>
                                    {column.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>

                    {result !== null && (
                        <>
                            <RenderData title="Operating Revenue" data={{
                                "Total Operating Revenue": result?.total_operating_revenue,
                                "Rooms": {
                                    "Operating Revenue Rooms Total": result?.operating_revenue_rooms,
                                    "No Show Revenue": result?.operating_revenue_rooms_no_show,
                                    "Day Use": result?.operating_revenue_rooms_day_use,
                                    "Early Departure": result?.operating_revenue_rooms_early_departure,
                                    "Early Check-in": result?.operating_revenue_rooms_early_checkin,
                                    "Late Check-out": result?.operating_revenue_rooms_late_checkout,
                                    "Miscellaneous": result?.operating_revenue_rooms_misc
                                },
                                "F&B": {
                                    "Operating Revenue F&B": result?.operating_revenue_f_and_b,
                                    "Market Revenue": result?.operating_revenue_f_and_b_market,
                                    "Restaurant Revenue": result?.operating_revenue_f_and_b_restaurant,
                                    "Bar Revenue": result?.operating_revenue_f_and_b_bar
                                },
                                "Other Revenue Operated Departments": result?.other_revenue_operated_departments,
                            }} />
                            <Divider style={{ margin: '20px 0' }} />
                            <RenderData title="Total Department Expenses" data={{
                                "Total Departmental Expenses": result?.total_departmental_expenses,
                                "Rooms": {
                                    "Total Rooms Expenses": result?.departmental_expenses_rooms,
                                    "Payroll Expenses": result?.departmental_expenses_rooms_payroll_expenses,
                                    "Supplemental Pay": result?.departmental_expenses_rooms_supplemental_pay,
                                    "Employee Benefits": result?.departmental_expenses_rooms_employee_benefits,
                                    "Other": result?.departmental_expenses_rooms_other
                                },
                                "F&B": {
                                    "Departmental Expenses F&B": result?.departmental_expenses_f_and_b,
                                    "Cost of Sales": result?.departmental_expenses_f_and_b_cost_of_sales,
                                    "Salaries and Wages": result?.departmental_expenses_f_and_b_salaries_and_wages,
                                    "Payroll Related Expenses": result?.departmental_expenses_f_and_b_payroll_related_expenses,
                                    "Supplemental Pay": result?.departmental_expenses_f_and_b_supplemental_pay,
                                    "Employee Benefits": result?.departmental_expenses_f_and_b_employee_benefits,
                                    "Other": result?.departmental_expenses_f_and_b_other
                                },
                                "Departmental Expenses Other": result?.departmental_expenses_other,
                            }} />

                            <Divider style={{ margin: '20px 0' }} />
                            <RenderData title="Undistributed Expenses" data={{
                                "Total Undistributed Expenses": result?.total_undistributed_expenses,
                                "Administration/General": {
                                    "Total Administration/General Expenses": result?.undistributed_expenses_administration_general,
                                    "Salaries and Wages": result?.undistributed_expenses_administration_general_salaries_and_wages,
                                    "Payroll Related Expenses": result?.undistributed_expenses_administration_general_payroll_related_expenses,
                                    "Supplemental Pay": result?.undistributed_expenses_administration_general_supplemental_pay,
                                    "Employee Benefits": result?.undistributed_expenses_administration_general_employee_benefits,
                                    "Other": result?.undistributed_expenses_administration_general_other
                                },
                                "Information/Telecomm": {
                                    "Total Information/Telecomm Expenses": result?.undistributed_expenses_information_telecomm,
                                    "Internet Services": result?.undistributed_expenses_information_telecomm_internet_services,
                                    "Telephone Equipment Service": result?.undistributed_expenses_information_telecomm_telephone_equipment_service,
                                    "Long Distance Call": result?.undistributed_expenses_information_telecomm_long_distance_call,
                                    "Contract Service General": result?.undistributed_expenses_information_telecomm_contract_service_general,
                                    "Hotel Level IT Expenses": result?.undistributed_expenses_information_telecomm_hotel_level_IT_expenses,
                                    "IT License": result?.undistributed_expenses_information_telecomm_IT_license,
                                    "Information Technology Services": result?.undistributed_expenses_information_telecomm_information_technology_services
                                },
                                "Sales/Marketing": {
                                    "Total Sales/Marketing Expenses": result?.undistributed_expenses_sales_marketing,
                                    "Salaries and Wages": result?.undistributed_expenses_sales_marketing_salaries_and_wages,
                                    "Payroll Related Expenses": result?.undistributed_expenses_sales_marketing_payroll_related_expenses,
                                    "Supplemental Pay": result?.undistributed_expenses_sales_marketing_supplemental_pay,
                                    "Employee Benefits": result?.undistributed_expenses_sales_marketing_employee_benefits,
                                    "Other": result?.undistributed_expenses_sales_marketing_other
                                },
                                "Franchise": result?.undistributed_expenses_franchise,
                                "Property Operations/Maintenance": {
                                    "Total Property Operations/Maintenance Expenses": result?.undistributed_expenses_property_operations_maintenance,
                                    "Salaries and Wages": result?.undistributed_expenses_property_ops_maint_salaries_and_wages,
                                    "Payroll Related Expenses": result?.undistributed_expenses_property_ops_maint_payroll_related_expenses,
                                    "Supplemental Pay": result?.undistributed_expenses_property_ops_maint_supplemental_pay,
                                    "Employee Benefits": result?.undistributed_expenses_property_ops_maint_employee_benefits,
                                    "Other": result?.undistributed_expenses_property_ops_maint_other
                                },
                                "Utilities": {
                                    "Total Utilities Expenses": result?.undistributed_expenses_utilities,
                                    "Electricity": result?.undistributed_expenses_utilities_electricity,
                                    "Gas": result?.undistributed_expenses_utilities_gas,
                                    "Water/Sewer": result?.undistributed_expenses_utilities_water_sewer
                                }
                            }} />
                            <Divider style={{ margin: '20px 0' }} />
                            <RenderData title="Non-Operating Expenses" data={{
                                "Total Non-Operating Expenses": result?.total_non_operating_expenses,
                                "Insurance": result?.non_operating_expenses_insurance,
                                "Other": result?.non_operating_expenses_other,
                                "Property Tax": result?.non_operating_expenses_property_tax,
                            }} />
                            <Divider style={{ margin: '20px 0' }} />
                            <RenderData title="House Profit" data={{
                                "Management Fees": result?.management_fees,
                            }} />
                            <Divider style={{ margin: '20px 0' }} />
                            <RenderData title={`Income Before Non-Oper Inc & Exp: ${result?.income_before_non_oper_expenses}`} data={{}} />
                            <Divider style={{ margin: '20px 0' }} />
                            <RenderData title="Other" data={{
                                "Date": result?.date,
                                "Available Rooms": result?.available_rooms,
                                "Occupied Rooms": result?.occupied_rooms,
                                "Occupancy": result?.occupancy,
                                "Average Rate": result?.average_rate,
                                "Rev Par": result?.rev_par,
                                "Income Before None Operating Expenses": result?.income_before_non_oper_expenses
                            }} />
                        </>
                    )}
                </div>
            </Container>
            {data && (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Occupancy</TableCell>
                                <TableCell>Average Rate</TableCell>
                                <TableCell>RevPAR</TableCell>
                                <TableCell>Total Operating Revenue</TableCell>
                                <TableCell>Operating Revenue Rooms</TableCell>
                                <TableCell>Operating Revenue F&B</TableCell>
                                <TableCell>Other Revenue Operated Departments</TableCell>
                                <TableCell>Total Departmental Expenses</TableCell>
                                <TableCell>Departmental Expenses Rooms</TableCell>
                                <TableCell>Departmental Expenses F&B</TableCell>
                                <TableCell>Departmental Expenses Other</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item) => (
                                <TableRow key={item.date}>
                                    <TableCell>{item?.date}</TableCell>
                                    <TableCell>{item?.occupancy}</TableCell>
                                    <TableCell>{item?.average_rate}</TableCell>
                                    <TableCell>{item?.rev_par}</TableCell>
                                    <TableCell>{item?.total_operating_revenue}</TableCell>
                                    <TableCell>{item?.operating_revenue_rooms}</TableCell>
                                    <TableCell>{item?.operating_revenue_f_and_b}</TableCell>
                                    <TableCell>{item?.other_revenue_operated_departments}</TableCell>
                                    <TableCell>{item?.total_departmental_expenses}</TableCell>
                                    <TableCell>{item?.departmental_expenses_rooms}</TableCell>
                                    <TableCell>{item?.departmental_expenses_f_and_b}</TableCell>
                                    <TableCell>{item?.departmental_expenses_other}</TableCell>
                                    <TableCell>{item?.total_undistributed_expenses}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_administration_general}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_information_telecomm}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_sales_marketing}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_franchise}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_property_operations_maintenance}</TableCell>
                                    <TableCell>{item?.undistributed_expenses_utilities}</TableCell>
                                    <TableCell>{item?.income_before_non_oper_expenses}</TableCell>
                                    <TableCell>{item?.management_fees}</TableCell>
                                    <TableCell>{item?.total_non_operating_expenses}</TableCell>
                                    <TableCell>{item?.non_operating_expenses_insurance}</TableCell>
                                    <TableCell>{item?.non_operating_expenses_other}</TableCell>
                                    <TableCell>{item?.non_operating_expenses_property_tax}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default FileUpload;
